.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.IncidentWizardNavigation-buttons {
  min-width: 58px !important;
  height: 58px !important;
}

.IncidentWizardNavigation-buttons .MuiSvgIcon-root {
  font-size: 3em;
}

.checklist-toolbox {
  position: fixed;
  top: 136px;
  overscroll-behavior-y: auto;
  bottom: 100px;
  width: 300px;
  left: 290px;
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 1279px) {

  .checklist-toolbox {
    top: 220px;
    left: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .IncidentWizardNavigation-buttons {
    min-width: 50px !important;
    height: 50px !important;
  }
  .IncidentWizardNavigation-buttons .MuiSvgIcon-root {
    font-size: 2em;
  }
}

@media only screen and (max-width: 320px) {
  .IncidentWizardNavigation-buttons {
    min-width: 42px !important;
    height: 42px !important;
  }
  .IncidentWizardNavigation-buttons .MuiSvgIcon-root {
    font-size: 1.5em;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.report-generation {
  padding: 5px;
  padding-right: 80px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  height: auto;
  color: white;
  z-index: 10000;
  max-width: 380px;
  min-width: 320px;
  box-sizing: border-box;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0px 0px 10px 0px #858585;
  -webkit-box-shadow: 0px 0px 10px 0px #858585;
  box-shadow: 0px 0px 10px 0px #858585;
  border: 1px solid #FFFFFF;
  background: #000000;
}

.report-generation li {
  list-style-type: none;
  position: relative;
  text-overflow: ellipsis;
}

.report-generation .report-generation-header h4 {
  font-size: 12px;
  margin: 5px 0px;
}

.report-generation label.danger {
  color: #fff;
  background-color: #d43339;
  border-radius: 3px;
  margin: 0 5px 0 0;
  padding: 0 5px;
}

.report-generation a {
  color: #ddd;
  font-size: 12px;
  text-decoration: underline;
  position: absolute;
  right: -70px;
  text-align: right;
  display: block;
  top: 1px;
}

.report-generation a:hover, .report-generation a:focus {
  color: #0094ff;
  text-decoration: underline;
}

.table-container-body td {
  padding-left: 6px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.directory:hover {
  font-weight: bold;
  background-color: #282c34;
}