html {}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background-color: #0E0E14;
}

code {
  font-family: "Montserrat", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

@keyframes pulse {
  from { transform: scale(1); }
  50% { transform: scale(0.6); }
  to { transform: scale(1); }
}

.contained-paragraph {
  display: -webkit-box;
  white-space: pre-line;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  overflow: hidden;
}

.contained-paragraph-1 {
  -webkit-line-clamp: 1;
}

.contained-paragraph-2 {
  -webkit-line-clamp: 2;
}

.contained-paragraph-3 {
  -webkit-line-clamp: 3;
}

.contained-paragraph-4 {
  -webkit-line-clamp: 4;
}

.contained-paragraph-5 {
  -webkit-line-clamp: 5;
}

.contained-paragraph-6 {
  -webkit-line-clamp: 6;
}

.animation-pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

/*select option {
  color: black;
}*/


/**  STYLE 3 */
div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #4a4a4a;
}

div::-webkit-scrollbar {
	width: 6px;
	background-color: #4a4a4a;
}

div::-webkit-scrollbar-thumb {
	background-color: #222;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  div.navigation-loading { 
    position: relative;
    width: 100%; 
    height: 30px;
    margin-bottom: 20px;
    /* animation: ease-in 4s linear infinite; */
    
    
    transition-duration: 1s;
    animation-name: growandshrinkheight;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
}

@keyframes growandshrinkheight {
  0% { transform: scale(1); }
  50% { transform: scale(0.7); }
  100% { transform: scale(1); }
}

